import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";

import "./src/styles/global.scss";

import React from "react";
import { GatsbyBrowser } from "gatsby";
import { ConsentProvider } from "./src/contexts/consent.context";
import {ParallaxProvider} from "react-scroll-parallax";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => (
    <ParallaxProvider>
        <ConsentProvider>
            {element}
        </ConsentProvider>
    </ParallaxProvider>
);
